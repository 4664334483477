import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Works from './pages/Works';
import Skills from './pages/Skills';
import './styles/main.module.css';

function App(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigation/>}>
                    <Route index element={<Home/>} />
                    <Route path="Works" element={<Works/>}/>
                    <Route path="Skills" element={<Skills/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;