export const websiteData = [
    {"name" : "PNI Manpower & Euro-Mena Healthcare", 
        "website" : "https://pnimanpower.net", 
        "image" : "/images/website/pnimanpower.jpg",
        "tools" : "HTML CSS Javascript PHP MySQL"    
    }, 
    {"name" : "AG Primes Mechanical", 
        "website" : "https://agprimes.com", 
        "image" : "/images/website/agprimes.jpg",
        "tools" : "HTML CSS Javascript"  
    }         
]

export const frontendmentorData = [
    {"name" : "Meet Landing Page", 
        "website" : "https://www.frontendmentor.io/solutions/meetlandingpage-TiWqLjaxne", 
        "image" : "/images/frontendmentor/meet-landing-form.jpg",
        "tools" : "HTML CSS"  
    }, 
    {"name" : "Calculator App", 
        "website" : "https://www.frontendmentor.io/solutions/calculatorappmain-xWJVUaKRpx", 
        "image" : "/images/frontendmentor/calculator-app-main.jpg",
        "tools" : "HTML CSS Javascript"  
    },
    {"name" : "Contact Form", 
        "website" : "https://www.frontendmentor.io/solutions/contactformmain-p9PM1vr3Bp", 
        "image" : "/images/frontendmentor/contact-form.jpg",
        "tools" : "HTML CSS Javascript"  
    },

    {"name" : "Recipe Page", 
        "website" : "https://www.frontendmentor.io/solutions/recipepagemain-Z2shkKNIH9", 
        "image" : "/images/frontendmentor/contact-form.jpg",
        "tools" : "HTML CSS"  
    },
    {"name" : "Social links profile", 
        "website" : "https://www.frontendmentor.io/solutions/sociallinksprofilemain-qCim4pmSQR", 
        "image" : "/images/frontendmentor/contact-form.jpg",
        "tools" : "HTML CSS"  
    },
    {"name" : "Blog preview card", 
        "website" : "https://www.frontendmentor.io/solutions/blogpreviewcardmain-a4q4C-hk66", 
        "image" : "/images/frontendmentor/contact-form.jpg",
        "tools" : "HTML CSS"  
    }
]