import SkillsStyle from '../styles/skills.module.css';
import Contact from '../components/Contact';

function Skills(){
    return(
        <section className={SkillsStyle.skills}>
            <h2 className={SkillsStyle.skills__h2}>Skills</h2>
            <ul className={SkillsStyle.skills__ul}>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>HTML</label>
                    <div className={SkillsStyle.skills__line}>
                        <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle80}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>CSS</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle70}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>JavaScript</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle60}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>PHP</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle50}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>MySQL</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle50}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>ReactJS</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle20}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>Adobe Illustrator</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle50}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>Adobe Photoshop</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle50}`}></div>
                    </div>
                </li>
                <li className={SkillsStyle.skills__li}>
                    <label className={SkillsStyle.skills__label}>Figma</label>
                    <div className={SkillsStyle.skills__line}>
                    <div className={`${SkillsStyle.skills__circle} ${SkillsStyle.skills__circle40}`}></div>
                    </div>
                </li>
            </ul>

        <h2 className={SkillsStyle.skills__h2}>Challenges</h2>
        <ul className={SkillsStyle.skills__ul}>
            <li className={SkillsStyle.skills__li}>
                <label className={SkillsStyle.skills__label}>CSS Battle</label>
                <a href="https://cssbattle.dev/player/lbaranda26" target="_blank">
                    Check score
                </a>
            </li>
            <li className={SkillsStyle.skills__li}>
                <label className={SkillsStyle.skills__label}>FrontendMentor</label>
                <a href="https://www.frontendmentor.io/profile/Lbaranda26" target="_blank">
                    Check Challenges
                </a>
            </li>
            <li className={SkillsStyle.skills__li}>
                <label className={SkillsStyle.skills__label}>GitHub</label>
                <a href="https://github.com/Lbaranda26" target="_blank">
                    Check Projects
                </a>
            </li>
        </ul>


            <Contact />
        </section>
    )
}
export default Skills;