import MainStyle from '../styles/main.module.css';
import HeaderStyle from '../styles/header.module.css';
import { Outlet, Link } from "react-router-dom";
import { useState } from 'react';

function Navigation(){

    const [menu, setMenu] = useState(false);
    const menuShow = () => {
        setMenu(true);
    }
    const menuHide = () => {
        setMenu(false);
    }
    return(
        <>
            <header>
                <div className={`${MainStyle.flexrow} ${MainStyle.flex_aligncenter} ${MainStyle.wrapper}`}>
                    <div className={HeaderStyle.logo}>
                        <Link to="/"><h1><mark className={HeaderStyle.mark}>LGAN</mark>Portfolio</h1></Link>
                    </div>                    
                    <nav className={HeaderStyle.navigation}>
                        <button 
                            className={HeaderStyle.navigation__toggle}
                            onClick={menuShow}
                        >
                            &#9776;
                        </button>
                        <ul className={`${HeaderStyle.navigation__ul} ${menu === false ? HeaderStyle.hide : HeaderStyle.show}`}>
                            <button
                                className={HeaderStyle.navigation__close}
                                onClick={menuHide}
                            >
                                X
                            </button>
                            <li>
                                <Link to="/">
                                    <button
                                    className={HeaderStyle.navigation__button}
                                    onClick={menuHide}>Home
                                    </button>
                                </Link>
                            </li>
                            <li>
                                <Link to="Works">
                                    <button
                                    className={HeaderStyle.navigation__button}
                                    onClick={menuHide}>Works
                                    </button>                      
                                </Link>
                            </li>
                            <li>
                                <Link to="Skills">
                                    <button
                                    className={HeaderStyle.navigation__button}
                                    onClick={menuHide}>Skills
                                    </button>                                
                                </Link>
                            </li>
                            <li><a className={HeaderStyle.resume} href='/LGANResume.pdf' download>Resume</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
            <main>
                <div className={`${MainStyle.flexrow} ${MainStyle.wrapper}`}>
                <Outlet />
                </div>
            </main>
        </>
    )
}

export default Navigation;