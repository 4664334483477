import ContactStyle from '../styles/contact.module.css';

function Contact(){
    return(
        <section className={ContactStyle.contact}>
            <ul className={ContactStyle.contact__ul}>
                <li className={ContactStyle.contact__li}>
                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=lbaranda26@gmail.com" target="_blank" rel="noopener noreferrer">
                    <img src="/images/icons/icons8-gmail-50.png" className={ContactStyle.contact__image}/>
                </a>
                </li>
                <li className={ContactStyle.contact__li}>
                <a href="https://m.me/LGan26" target="_blank" rel="noopener noreferrer">
                    <img src="/images/icons/icons8-messenger-50.png" className={ContactStyle.contact__image}/>
                </a>
                </li>
                <li className={ContactStyle.contact__li}>
                    <img src="/images/icons/icons8-linkedin-50.png" className={ContactStyle.contact__image}/>
                </li>
            </ul>
        </section>
    )
}
export default Contact;