import { Outlet, Link } from "react-router-dom";
import Contact from '../components/Contact';
import HomeStyle from '../styles/home.module.css';

function Home(){
    return(
        <section className={HomeStyle.intro}>
            <figure className={HomeStyle.intro__figure}>
                <figcaption className={HomeStyle.intro__figcaption}>Hi, I'm Luigi Owen</figcaption>
            </figure>
            <h1 className={HomeStyle.intro__h1}>Building Website</h1>
            <p className={HomeStyle.intro__p}>
                A Website Developer and Recruitment Officer in PNI International.<br/>
                I am pursuing to be a full time Web and Mobile Developer.<br/>
                Making an improvement everyday.
            </p>
            <div className={HomeStyle.intro__button_container}>
                <Link to="Works"><button className={HomeStyle.intro__button}>Works</button></Link>
            </div>
            <Contact />
        </section>
    )
}
export default Home;