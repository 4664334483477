import { Link } from "react-router-dom";
import WorksStyle from '../styles/works.module.css';
import { websiteData } from '../data/data';
import { frontendmentorData } from '../data/data';
import Contact from '../components/Contact';

function Works(){
    return(        
        <section className={WorksStyle.card__container}>
        <h2>Websites</h2>
        <div className={WorksStyle.card}>
            <ul className={WorksStyle.card__ul}>
                {websiteData.map((data, key) => {
                    return(
                    <li key={key} className={WorksStyle.card__li}>
                        <figure className={WorksStyle.card__figure}>
                            <img className={WorksStyle.card__img} src={`${data.image}`} alt="FrontendMentor Calculator App"/>
                        </figure>
                        <div className={WorksStyle.card__tools}>
                            <h3 className={WorksStyle.card__title}>{data.name}</h3>
                            <p>{data.tools}</p>
                            <a className={WorksStyle.card__a} href={data.website} target="_blank" rel="noopener noreferrer">
                            &#10149;
                            </a>
                        </div>
                    </li>
                    )
                })}
            </ul>
        </div>
        
        <h2>Frontend Mentor</h2>
        <div className={WorksStyle.card}>
            <ul className={WorksStyle.card__ul}>
                {frontendmentorData.map((data, key) => {
                    return(
                    <li key={key} className={WorksStyle.card__li}>
                        <figure className={WorksStyle.card__figure}>
                            <img className={WorksStyle.card__img} src={`${data.image}`} alt="FrontendMentor Calculator App"/>
                        </figure>
                        <div className={WorksStyle.card__tools}>
                            <h3 className={WorksStyle.card__title}>{data.name}</h3>
                            <p>{data.tools}</p>
                            <a className={WorksStyle.card__a} href={data.website} target="_blank" rel="noopener noreferrer">
                            &#10149;
                            </a>
                        </div>                       
                    </li>
                    )
                })}

            </ul>
        </div>
        <Contact />
        </section>
    )
}

export default Works;


//REFERENCE
//https://www.pluralsight.com/resources/blog/guides/load-and-render-json-data-into-react-components
//https://stackoverflow.com/questions/47196800/reactjs-and-images-in-public-folder
//https://stackoverflow.com/questions/48924672/can-i-use-href-tag-in-reactjs